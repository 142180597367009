import axios from "axios";

import React, { Component } from "react";
import { Container, Card, Button, Form, Alert } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

class Competition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImage: false,
      showFAQ: false,
      showExampleQuestions: false,
      showPrice: false,
      title: "",
      message: "",
      firstname: "",
      lastname: "",
      postalCode: "",
      location: "",
      streetAddress: "",
      country: "",
      email: "",
      error: null,
      success: null,
    };

    this.exampleQuestionsRef = React.createRef();

    this.clickShowImg = this.clickShowImg.bind(this);
    this.clickShowFaq = this.clickShowFaq.bind(this);
    this.clickShowExampleQuestions = this.clickShowExampleQuestions.bind(this);
    this.clickShowPrice = this.clickShowPrice.bind(this);
  }

  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }

  clickShowImg() {
    this.setState((prevState) => ({
      showImage: !prevState.showImage, // Toggle showImage state
      showFAQ: false,
      showExampleQuestions: false,
      showPrice: false,
    }));
  }

  clickShowExampleQuestions() {
    this.setState((prevState) => ({
      showImage: false,
      showFAQ: false,
      showExampleQuestions: !prevState.showExampleQuestions,
      showPrice: false,
    }));
  }

  clickShowFaq() {
    this.setState((prevState) => ({
      showImage: false,
      showFAQ: !prevState.showFAQ,
      showExampleQuestions: false,
      showPrice: false,
    }));
  }

  clickShowPrice() {
    this.setState((prevState) => ({
      showImage: false,
      showFAQ: false,
      showExampleQuestions: false,
      showPrice: !prevState.showPrice,
    }));
  }

  async handleSubmit(e) {
    e.preventDefault();
    const {
      title,
      message,
      firstname,
      lastname,
      postalCode,
      location,
      streetAddress,
      country,
      email,
    } = this.state;

    if (
      !title ||
      !message ||
      !firstname ||
      !lastname ||
      !postalCode ||
      !location ||
      !streetAddress ||
      !country ||
      !email
    ) {
      this.setState({ error: "Alle Felder müssen ausgefüllt sein." });
      return;
    }

    try {
      const response = await axios.post(
        "https://burgverein-hohenfels.de/questions/postEntry",
        {
          title,
          message,
          firstname,
          lastname,
          postalCode,
          location,
          streetAddress,
          country,
          email,
        }
      );

      if (response.status === 200) {
        this.setState({
          success: "Frage erfolgreich eingereicht!",
        });
      }
    } catch (error) {
      console.error("Error posting entry:", error);
      this.setState({
        error: "Es gab einen Fehler beim Einreichen der Frage.",
      });
    }
  }

  render() {
    const {
      showImage,
      showFAQ,
      showExampleQuestions,
      showPrice,
      error,
      success,
    } = this.state;

    const gedichtsband_dec = (
      <div className="price-desc">
        <div className="price-text"><b>
          „Ritterliche Dichtkunst“ – Ein Stück Geschichte für Sammler und
          Liebhaber!{" "}
        </b>
          <br />
          Zum 850-jährigen Jubiläum der Burg Hohenfels präsentieren wir voller
          Stolz unseren limitierten Gedichtband „Ritterliche Dichtkunst“. Dieses
          besondere Werk ist mehr als ein Buch – es ist ein Sammlerstück, das
          Geschichte und Poesie auf einzigartige Weise miteinander verbindet.
          <br /> <br />
          <b>Das erwartet dich auf 132 Seiten:</b>
          <br />
          ● 28 klassische Gedichte von Dichtergrößen wie Goethe, Schiller,
          Fontane, Wilhelm Busch, Storm, Uhland und Bechstein – alle rund um die
          Themen Rittertum und Mittelalter.
          <br />
          ● 10 eigens verfasste Gedichte, inspiriert von der Magie und den
          Geschichten der Burg Hohenfels – Worte, die möglicherweise den
          Grundstein für zukünftige Projekte legen und an Bedeutung gewinnen
          werden.
          <br />
          ● Ein streng limitiertes Sammlerstück: Nur 85 durchnummerierte Exemplare
          – eines für jedes Jahrzehnt seit der ersten Erwähnung der Wehranlage im
          Jahr 1174.
          <br /> <br />
          <b>So sicherst du dir dein Exemplar:</b>
          <br />
          ● Gewinne eines von 3 exklusiven Exemplaren im Rahmen unseres
          Jubiläums-Gewinnspiels!
          <br />
          ● Oder sichere dir deinen eigenen Band für nur 10 Euro – solange der
          Vorrat reicht.
          <br /> <br />
          <b>
            Ein Werk, das die Vergangenheit lebendig werden lässt – und vielleicht
            schon bald selbst Teil der Geschichte wird.
          </b><br /></div>
        <div id="price-img">
          <img id="selbstverlagslogo" alt="selbstverlagslogo" src="/assets/images/selbstverlagslogo.png"></img>
        </div>
      </div>
    );

    return (
      <div id="competition">
        <Container className="main-container">
          <Card className="competition-welcome-card">
            <Card.Header>
              Herzlich willkommen bei unserem Jubiläums-Gewinnspiel zum <br />{" "}
              850-jährigen Bestehen der Burg Hohenfels!
            </Card.Header>
            <Card.Body>
              Hast du dich schon einmal gefragt, welche Geschichten die Burg
              Hohenfels in den letzten 850 Jahren erlebt hat? Jetzt ist deine
              Gelegenheit, Teil dieses besonderen Jubiläums zu werden! <br />
              <br />
              Hier erfährst du alles, was du wissen musst:
              <br />
              <ul>
                <li>
                  Die Hintergründe des Gewinnspiels findest du unter (Button
                  „Beschreibung“).
                </li>
                <li>
                  Antworten zu möglichen Fragen liefert dir (Button „Wer, Was,
                  Wo?“).
                </li>
                <li>
                  Und falls du noch Inspiration benötigst, helfen wir dir mit
                  (Button „Hilfe – was soll ich fragen?“).
                </li>
              </ul>
              Lass uns gemeinsam die Geheimnisse des Hohenfels ergründen und die
              Geschichte dieses besonderen Ortes wieder lebendig werden lassen!
            </Card.Body>
          </Card>

          <Card className="competition-card-btn">
            <Card.Body>
              <Button href="#competition-img" onClick={this.clickShowImg}>
                {" "}
                Beschreibung{" "}
              </Button>
              <Button href="#faq" onClick={this.clickShowFaq}>
                {" "}
                Wer, Was, Wo?
              </Button>
              <Button
                href="#exampleQuestions"
                onClick={this.clickShowExampleQuestions}
              >
                {" "}
                Hilfe – was soll ich fragen?
              </Button>
              <Button href="#gedichtband-img" onClick={this.clickShowPrice}>
                {" "}
                Der Gewinn
              </Button>
            </Card.Body>
          </Card>
          <dív id="secret" />
          {showImage && (
            <CardWithImageComponent
              image="/assets/images/gewinnspiel.png"
              id="competition-img"
              className="card-with-image"
            />
          )}
          {showFAQ && (
            <Card id="faq">
              <Card.Header>
                <b>
                  Gewinnspiel „85 Fragen – eine für jedes Jahrzehnt“ <br />
                  Wie funktioniert das jetzt?
                </b>
              </Card.Header>
              <Card.Body>
                <b>1. Wer kann teilnehmen?</b>
                <br />
                Jeder, der Fragen zur Geschichte des Hohenfels hat, kann
                mitmachen – unabhängig von Alter oder Wohnort.
                <br />
                <br />
                <b>2. Wie viele Fragen darf ich einreichen?</b>
                <br />
                Du kannst so viele Fragen stellen, wie du möchtest – deiner
                Kreativität sind keine Grenzen gesetzt! Für die Verlosung
                berücksichtigen wir jedoch nur eine Frage pro Person.
                <br />
                <br />
                <b>3. Wie wird die Frage für die Verlosung ausgewählt?</b>
                <br />
                Wenn du mehrere Fragen einreichst, wählen wir die kreativste
                oder originellste aus, die in die Verlosung einfließt. So hat
                jede Person die gleiche Chance, einen unserer Gedichtbände zu
                gewinnen.
                <br />
                <br />
                <b>4. Was gibt es zu gewinnen?</b>
                <br />
                Wir verlosen drei Exemplare des Gedichtbands „Ritterliche
                Dichtkunst“, der klassische und moderne Gedichte rund um Ritter
                und Mittelalter vereint.
                <br />
                <br />
                <b>5. Kann ich meine Frage anonym einreichen?</b>
                <br />
                Ja, anonyme Fragen sind möglich, allerdings können wir dich in
                diesem Fall nicht für die Verlosung berücksichtigen.
                <br />
                <br />
                <b>6. Wie und wo reiche ich meine Frage ein?</b>
                <br />
                Fragen können postalisch an unsere Geschäftsadresse, per E-Mail
                oder über Instagram eingereicht werden.
                <br />
                <br />
                <b>7. Wann endet das Gewinnspiel?</b>
                <br />
                Das Gewinnspiel läuft bis zum 31. Dezember 2024. Die Gewinner
                werden am 31. Januar 2025 während unserer Jahreshauptversammlung
                gezogen.
                <br />
                <br />
                <b>8. Werden alle Fragen beantwortet?</b>
                <br />
                Wir bemühen uns, so viele Fragen wie möglich zu beantworten –
                sei es über unsere Website, Social-Media-Kanäle oder in
                zukünftigen Projekten.
                <br />
                <br />
                <b>9. Wie erfahre ich, ob ich gewonnen habe?</b>
                <br />
                Die Gewinner werden im Februar 2025 über die angegebene
                Kontaktmöglichkeit (z.B.: per E-Mail) benachrichtigt und auf
                unserer Website, sowie unseren Social-Media-Kanälen bekannt
                gegeben.
              </Card.Body>
            </Card>
          )}
          {showExampleQuestions && (
            <Card id="exampleQuestions" ref={this.exampleQuestionsRef}>
              <Card.Header>Beispiele für Fragen</Card.Header>
              <br />
              <Card.Body>
                Hier ein paar Inspirationen:
                <br />
                <br />
                • „Warum heißt es auch Schloss Hohenfels – wo da oben doch
                nichts Großes gestanden haben kann“
                <br />
                <br />
                • „warum gibt es die Burg nicht mehr?“
                <br />
                <br />
                • „Gibt es auf dem Hohenfels einen Schatz?“
                <br />
                <br />
                • „Wie alt ist der Hohenfels?“
                <br />
                <br />
              </Card.Body>
            </Card>
          )}
          {showPrice && (
            <CardWithImageComponent
              image="/assets/images/general_images/gewinn.jpg"
              id="gedichtband-img"
              className="card-with-image"
              content={gedichtsband_dec}
            />
          )}

          <div className="question-form">
            <h1>Neue Frage einreichen</h1>
            <Form onSubmit={(e) => this.handleSubmit(e)} className="card-form">

              <Form.Group controlId="formTitle">
                <Form.Label>Titel</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Gib deiner Frage einen Titel"
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Frage</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Schreibe deine Frage"
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formFirstname" className="mt-3">
                <Form.Label>Dein Vorname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vorname"
                  onChange={(e) => this.setState({ firstname: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formLastname" className="mt-3">
                <Form.Label>Dein Nachname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nachname"
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formPostleitzahl" className="mt-3">
                <Form.Label>Deine Postleitzahl</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Postleitzahl"
                  onChange={(e) =>
                    this.setState({ postalCode: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formLocation" className="mt-3">
                <Form.Label>Ort</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ort"
                  onChange={(e) => this.setState({ location: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formStreet" className="mt-3">
                <Form.Label>Straße und Hausnummer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Straße"
                  onChange={(e) =>
                    this.setState({ streetAddress: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formCountry" className="mt-3">
                <Form.Label>Land</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Land"
                  onChange={(e) => this.setState({ country: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email Adresse</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Mail Adresse eingeben"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <Form.Text className="text-muted">
                  Wir löschen die E-Mail nach Ablauf des Gewinnspieles
                </Form.Text>
              </Form.Group>
              <div className="btn-form-field">
                <Button className="mt-3" variant="primary" type="submit">
                  Frage einreichen
                </Button>
              </div>
            </Form>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
          </div>

          <Card id="competition-data-protection-card">
            <Card.Body>
              <b>
                Der Rechtsweg ist ausgeschlossen.
                <br />
                Datenschutz: Die angegebenen Kontaktdaten, die für die Ziehung
                der Gewinner notwendig sind, werden vertraulich behandelt und
                nach Abschluss des Gewinnspieles gelöscht.
              </b>
              <br />
              <br />
              <div className="competition-data-protection">
                <b>
                  Datenschutzhinweis zur Veröffentlichung der Gewinner unseres
                  Gewinnspiels
                </b>
                <br />
                Wir freuen uns über Ihre Teilnahme an unserem Gewinnspiel, bei
                dem drei Gedichtbände verlost werden. Im Rahmen der Abwicklung
                des Gewinnspiels behalten wir uns vor, die Namen der Gewinner
                auf unserer Webseite zu veröffentlichen.
                <br />
                Diese Veröffentlichung erfolgt auf Grundlage von Art. 6 Abs. 1
                lit. f DSGVO. <br />
                Unser berechtigtes Interesse liegt darin, die Transparenz des
                Gewinnspiels sicherzustellen und dessen ordnungsgemäßen Ablauf
                nach außen hin zu dokumentieren. Dadurch möchten wir möglichen
                Missverständnissen vorbeugen und das Vertrauen unserer
                Teilnehmer stärken.
                <br />
                Die Veröffentlichung beschränkt sich auf Vor- und Nachnamen
                (gegebenenfalls in abgekürzter Form, z. B. „Maria K.“) und dient
                ausschließlich den genannten Zwecken. Sollten Sie mit der
                Veröffentlichung nicht einverstanden sein, bitten wir Sie, uns
                dies vorab schriftlich mitzuteilen. Wir versichern, dass Ihre
                Daten im Rahmen des Gewinnspiels nicht für andere Zwecke
                verwendet oder an Dritte weitergegeben werden. Bei Fragen oder
                Anliegen können Sie sich jederzeit an uns wenden. Unsere
                Kontaktdaten finden Sie in der Datenschutzerklärung.
              </div>
            </Card.Body>
          </Card>

          <Card className="backToStart">
            <Card.Body>
              <a href="/" style={{ textAlign: "center" }}>
                {" "}
                Zurück zur Startseite{" "}
              </a>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default Competition;
